@import url(https://fonts.googleapis.com/css2?family=Martel+Sans:wght@900&family=Martel+Sans:wght@200;300;400;700;900&family=Nunito:wght@200;400;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h1, h2, h3, h4, h5, h6, p, a, ul, li {
  line-height: 1;
  color: white;
  font-family: 'Nunito';
  font-weight: 400; }
  h1 b, h2 b, h3 b, h4 b, h5 b, h6 b, p b, a b, ul b, li b {
    font-weight: bold; }
  h1 u, h2 u, h3 u, h4 u, h5 u, h6 u, p u, a u, ul u, li u {
    text-decoration: underline; }

a {
  text-decoration: none; }
  a:hover {
    color: white;
    font-weight: 900;
    text-decoration: underline; }

html {
  font-size: 20px; }
  @media screen and (max-width: 1919px) {
    html {
      font-size: 18px; } }
  @media screen and (max-width: 1279px) {
    html {
      font-size: 16px; } }
  @media screen and (max-width: 959px) {
    html {
      font-size: 16px; } }
  @media screen and (max-width: 599px) {
    html {
      font-size: 16px; } }

div {
  box-sizing: border-box; }

html {
  height: 100%; }
  html body {
    height: 100%; }
    html body #root {
      height: 100%; }
      html body #root .app {
        height: 100%; }
        html body #root .app .content-container {
          height: 100%; }

.app .content-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 80%;
  margin: 0 auto; }

.app.smDown .content-container {
  overflow: scroll; }

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  background: radial-gradient(#70365b 5%, #2b1e47 25%, #070814 60%);
  height: 100%; }
  .background canvas {
    opacity: .4; }

.about {
  width: 100%;
  overflow: scroll;
  background: radial-gradient(circle at center, #2b1e47 0%, rgba(0, 0, 0, 0) 45%);
  position: relative;
  overflow: hidden; }
  .about .logo {
    text-align: center;
    margin-top: 80px; }
  .about p, .about h3, .about li {
    line-height: 1.3; }
  .about p {
    font-weight: bold;
    font-size: 20px; }
  .about h3 {
    font-size: 30px;
    font-weight: 900; }
  .about li {
    line-height: 2;
    font-weight: bold;
    font-size: 20px; }
  .about a {
    font-weight: bold;
    text-decoration: underline; }
  .about.mdDown .about-scroller .about-content {
    width: 80%; }
  .about.smDown {
    overflow: visible;
    overflow: initial; }
    .about.smDown .about-scroller .about-content {
      width: 100%;
      padding-top: 140px; }
    .about.smDown:before, .about.smDown:after {
      background: none; }
  .about .about-scroller {
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .about .about-scroller .about-content {
      width: 60%;
      padding: 120px 0px 160px 0px; }
    .about .about-scroller .camel {
      text-transform: capitalize; }
  .about:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, transparent 85%, #070814);
    pointer-events: none; }
  .about:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent 600px, white);
    background: linear-gradient(transparent 85%, #070814);
    pointer-events: none; }

.sale {
  position: relative;
  overflow: hidden;
  height: 100%; }
  .sale.smDown:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent 85%, #070814);
    pointer-events: none; }
    .sale.smDown:after .sale-description {
      margin: 0px;
      padding: 0px; }
  .sale.smDown {
    overflow: visible;
    overflow: initial;
    height: auto; }
    .sale.smDown .sale-scroller {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .sale.smDown .sale-scroller .sale-description {
        margin: 0;
        padding: 0; }
      .sale.smDown .sale-scroller .warning {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
        padding-top: 100px; }
        .sale.smDown .sale-scroller .warning p {
          margin-top: .7rem;
          text-align: center;
          font-size: 1.2rem;
          line-height: 1; }
  .sale .debug {
    text-align: center;
    display: none; }
  .sale .sale-scroller {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .sale .sale-scroller .warning {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      min-height: 40px;
      padding-top: 50px;
      position: absolute;
      top: 0; }
      @media (max-height: 800px) {
        .sale .sale-scroller .warning {
          padding-top: 0px; } }
      .sale .sale-scroller .warning img {
        margin-right: 5px; }
      .sale .sale-scroller .warning img.spinner {
        height: 30px; }
      .sale .sale-scroller .warning p {
        line-height: 0; }
      .sale .sale-scroller .warning a {
        font-weight: 900; }
    .sale .sale-scroller .sale-content {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-align-items: center;
              align-items: center; }
    .sale .sale-scroller.smDown {
      overflow: scroll; }
      .sale .sale-scroller.smDown .sale-content {
        padding: 200px 0px 140px 0px;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        bottom: 0px; }
      .sale .sale-scroller.smDown .sale-description {
        width: 100%; }
        .sale .sale-scroller.smDown .sale-description .title {
          text-align: center;
          margin-bottom: 3rem; }
        .sale .sale-scroller.smDown .sale-description p {
          font-size: 1.4rem; }
      .sale .sale-scroller.smDown .sale-action {
        width: 100%;
        padding: 0; }
        .sale .sale-scroller.smDown .sale-action .content {
          padding: 0px;
          padding-top: 3rem; }
    .sale .sale-scroller .sale-description {
      width: 50%;
      padding-right: 2em; }
      .sale .sale-scroller .sale-description .title {
        margin: 0;
        font-family: 'Nunito', Arial;
        font-size: 3.6rem;
        margin-bottom: 2rem; }
        .sale .sale-scroller .sale-description .title span {
          font-family: 'Martel Sans';
          font-weight: 200; }
      .sale .sale-scroller .sale-description p {
        font-size: 1.2rem;
        color: white;
        margin: 10px 0px;
        line-height: 1.4;
        font-weight: 600; }
        .sale .sale-scroller .sale-description p.disclaimer {
          margin-top: 20px;
          font-size: 18px;
          font-style: italic;
          font-weight: bold; }
    .sale .sale-scroller .sale-action {
      width: 50%; }
      .sale .sale-scroller .sale-action .content {
        background: white;
        padding: 35px;
        color: black;
        border-radius: 20px;
        background: none;
        color: white; }
        .sale .sale-scroller .sale-action .content .ico-description {
          text-align: center;
          margin-bottom: 20px; }
          .sale .sale-scroller .sale-action .content .ico-description p {
            color: white;
            font-size: 1.1rem;
            font-weight: 600; }
        .sale .sale-scroller .sale-action .content .contract-over .heading {
          font-size: 1.5rem;
          text-align: center;
          font-weight: bold; }
        .sale .sale-scroller .sale-action .content .contract-over .message {
          margin: 2rem 0rem;
          line-height: 1.3;
          font-weight: 600; }
        .sale .sale-scroller .sale-action .content .countdown-section {
          margin: 40px 0; }
        .sale .sale-scroller .sale-action .content .progress-bar-section {
          margin-bottom: 2.5rem; }
          .sale .sale-scroller .sale-action .content .progress-bar-section p {
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
            margin: 0;
            cursor: default; }
          .sale .sale-scroller .sale-action .content .progress-bar-section.smDown p {
            font-size: 1.1rem; }
          .sale .sale-scroller .sale-action .content .progress-bar-section.smDown .token-count {
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .sale .sale-scroller .sale-action .content .progress-bar-section.smDown .token-count .tokens-owned {
              margin-bottom: 10px; }
          .sale .sale-scroller .sale-action .content .progress-bar-section.smDown .conversion-msg {
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .sale .sale-scroller .sale-action .content .progress-bar-section.smDown .conversion-msg .network {
              margin-top: .5rem; }
          .sale .sale-scroller .sale-action .content .progress-bar-section .conversion-msg {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            padding: 0px 7px; }
          .sale .sale-scroller .sale-action .content .progress-bar-section .token-count {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            padding: 0px 7px; }
          .sale .sale-scroller .sale-action .content .progress-bar-section .progress-bar {
            margin: .4rem 0; }
        .sale .sale-scroller .sale-action .content .button_wrapper {
          text-align: center; }

.button {
  cursor: pointer;
  height: 54px;
  border-radius: 40px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px 20px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: white;
  background: white;
  color: #70365b;
  text-transform: uppercase; }
  .button img {
    height: 20px; }
  .button.invert {
    background: linear-gradient(90deg, #833a68 0%, #2b1e47 100%);
    color: white; }
  .button.disabled {
    opacity: .5;
    cursor: not-allowed; }
  .button.smDown {
    font-size: 12px;
    padding: 0px 15px; }

.countdown_wrapper {
  border: 5px solid #70365b;
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  border: none;
  background: white; }
  .countdown_wrapper .countdown {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around; }
    .countdown_wrapper .countdown .counter_box {
      text-align: center;
      display: inline; }
      .countdown_wrapper .countdown .counter_box .value {
        margin-bottom: 10px;
        font-weight: 700;
        font-family: 'Nunito'; }
      .countdown_wrapper .countdown .counter_box .text {
        font-size: 12px;
        font-weight: 500; }
      .countdown_wrapper .countdown .counter_box .value, .countdown_wrapper .countdown .counter_box .separator {
        font-size: 40px; }
      .countdown_wrapper .countdown .counter_box .value, .countdown_wrapper .countdown .counter_box .text, .countdown_wrapper .countdown .counter_box .separator {
        color: #484957; }
    .countdown_wrapper .countdown.smDown .counter_box .value, .countdown_wrapper .countdown.smDown .counter_box .separator {
      font-size: 33px; }

.progress-bar .progress-bar_shell {
  height: 40px;
  border: 1px solid #70365b;
  border-radius: 20px;
  background: white;
  padding: 5px; }
  .progress-bar .progress-bar_shell .progress {
    background: linear-gradient(90deg, #2b1e47, #70365b);
    height: 100%;
    border-radius: 20px;
    position: relative;
    min-width: 40px;
    box-shadow: 4px 1px 7px 0px #949494;
    transition: width 1s; }
    .progress-bar .progress-bar_shell .progress .progress-label {
      position: absolute;
      right: 8px;
      height: 30px;
      font-size: 16px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      height: 100%; }
      .progress-bar .progress-bar_shell .progress .progress-label span {
        font-weight: 700; }

.modal-background {
  width: 100vw;
  height: 100vh;
  background: rgba(37, 37, 37, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 15000; }
  .modal-background .modal {
    width: 400px;
    min-height: 200px;
    background: white;
    border-radius: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
    z-index: 500;
    padding-top: 0px; }
    .modal-background .modal p, .modal-background .modal span, .modal-background .modal input {
      color: #484957; }

.connection-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .connection-modal p {
    text-align: center; }
  .connection-modal .connecting {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .connection-modal .connecting img {
      height: 1rem;
      margin-right: 5px; }
    .connection-modal .connecting p {
      margin: 20px 0px 0px 0px; }
  .connection-modal .error .title {
    font-weight: bolder; }
  .connection-modal .error .message {
    color: #b65555; }
  .connection-modal .error p {
    line-height: 1.1;
    margin: 1rem 0rem; }

.purchase-modal {
  text-align: center;
  width: 100%; }
  .purchase-modal .header {
    height: 60px; }
    .purchase-modal .header p {
      font-size: .9rem;
      font-weight: bold; }
  .purchase-modal .trade {
    margin-bottom: 1.3rem; }
    .purchase-modal .trade .quantity, .purchase-modal .trade .price {
      height: 80px;
      border: 1px solid #eee;
      border-radius: 20px;
      position: relative; }
      .purchase-modal .trade .quantity .label, .purchase-modal .trade .price .label {
        color: black;
        font-size: 12px;
        top: 4px;
        height: 30px;
        width: 100%;
        text-align: left;
        padding: 10px 0px 0px 16px;
        font-weight: bold; }
      .purchase-modal .trade .quantity .value, .purchase-modal .trade .price .value {
        padding: 0px 20px 10px 16px;
        height: 50px;
        font-size: 1.5rem;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        -webkit-align-items: center;
                align-items: center; }
        .purchase-modal .trade .quantity .value input, .purchase-modal .trade .price .value input {
          font-size: 1.5rem;
          font-family: "Nunito"; }
        .purchase-modal .trade .quantity .value p, .purchase-modal .trade .price .value p {
          margin: 0;
          width: 100%;
          font-family: "Nunito"; }
          .purchase-modal .trade .quantity .value p .placeholder, .purchase-modal .trade .price .value p .placeholder {
            color: #aaa; }
        .purchase-modal .trade .quantity .value .currency, .purchase-modal .trade .price .value .currency {
          width: 40px;
          color: black;
          font-size: 20px; }
    .purchase-modal .trade input {
      border: none;
      height: 100%;
      width: 100%;
      font-size: 2rem;
      padding-right: .7rem;
      outline: none;
      font-family: "Nunito"; }
      .purchase-modal .trade input::-webkit-input-placeholder {
        color: #aaa;
        opacity: 1; }
      .purchase-modal .trade input:-ms-input-placeholder {
        color: #aaa;
        opacity: 1; }
      .purchase-modal .trade input::placeholder {
        color: #aaa;
        opacity: 1; }
    .purchase-modal .trade .price {
      color: black;
      text-align: left; }
    .purchase-modal .trade .arrow {
      margin: 1rem 0rem;
      height: 14px; }
      .purchase-modal .trade .arrow img {
        height: 100%; }
  .purchase-modal .input-purchase {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: none; }
    .purchase-modal .input-purchase .left {
      min-width: 100px;
      text-align: right; }
    .purchase-modal .input-purchase .right {
      margin-left: 10px;
      min-width: 150px;
      text-align: left; }
    .purchase-modal .input-purchase input {
      border: none;
      width: 70px;
      border-bottom: 2px solid #484957;
      text-align: center;
      font-size: 20px; }
    .purchase-modal .input-purchase span {
      font-size: 20px;
      color: #484957; }
  .purchase-modal .status {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
    height: 0;
    transition: height 2s;
    overflow: hidden; }
    .purchase-modal .status.show {
      height: 70px; }
    .purchase-modal .status p.error {
      font-size: 16px;
      color: #70365b;
      font-weight: 700;
      margin: 0; }
    .purchase-modal .status img {
      height: 40%; }
  .purchase-modal .button-wrapper {
    text-align: center; }

.confirmation-modal .check {
  height: 60px;
  text-align: center;
  margin: 40px 0px 30px 0px; }
  .confirmation-modal .check img {
    height: 100%;
    fill: #70365b; }

.confirmation-modal .thank-you {
  text-align: center;
  font-weight: bold; }

.confirmation-modal .message {
  line-height: 1.3;
  text-align: center; }
  .confirmation-modal .message a {
    color: #70365b;
    font-weight: bold; }

.confirmation-modal .tx-link {
  margin-top: 30px;
  text-align: center;
  cursor: pointer; }
  .confirmation-modal .tx-link a {
    font-weight: bold;
    color: #70365b; }
    .confirmation-modal .tx-link a:hover {
      text-decoration: underline; }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  padding: 0;
  background: none;
  height: 80px; }
  .header a:hover {
    text-decoration: none; }
  .header .brand, .header .link {
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .header .brand img {
    height: 55px;
    width: 35px; }
  .header .brand p {
    font-size: 28px;
    font-weight: 300;
    font-family: 'Martel Sans';
    position: relative;
    left: 10px; }
  .header .link {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .header .link p {
      font-size: 18px;
      font-weight: bolder; }
    .header .link.active p {
      font-weight: 900;
      position: relative;
      left: 2px;
      text-decoration: underline; }
  .header.smDown {
    position: fixed;
    background: #070814;
    z-index: 10000;
    width: 80%; }

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 70px;
  min-height: 70px; }
  .footer a, .footer p {
    font-size: .8rem;
    margin: 0; }
  .footer.mdDown a, .footer.mdDown p {
    font-size: 1rem;
    margin: 0; }
  .footer.smDown {
    height: 170px;
    min-height: 170px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    background: #070814; }
    .footer.smDown a, .footer.smDown p {
      font-size: 1.1rem; }
    .footer.smDown .profile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-align-items: center;
              align-items: center;
      width: 60%; }

