@import '../../constants.scss';

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    min-height: 70px;

    a, p {
        font-size: .8rem;
        margin: 0;
    }

    &.mdDown {
        a, p {
            font-size: 1rem;
            margin: 0;
        }
    }

    &.smDown {
        height: 170px;
        min-height: 170px;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        background: $tertiary;

        a, p {
            font-size: 1.1rem;
        }

        .profile {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 60%;
        }
    }
}